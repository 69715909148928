import { type Keyframes, keyframes } from 'styled-components'

export const translateUpAndFade = (height = 100): Keyframes => keyframes`
  from {
    opacity: 0;
    height: 0px;
  }
  to {
    opacity: 1;
    height: ${height}px;
  }
`

export const translateDownAndFade = (height = 100): Keyframes => keyframes`
  from {
    opacity: 1;
    height: ${height}px;
  }
  to {
    opacity: 0;
    height: 0px;
  }
`

export const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
