import type { Account } from '~publish/legacy/accountContext/types'

export const hasDismissedObject = (args: {
  account: Account
  objectId: string
}): boolean => {
  const { account, objectId } = args

  if (!account) {
    return false
  }

  if (!account.currentOrganization) {
    return false
  }

  if (!account.currentOrganization.dismissedObjects) {
    return false
  }

  return (
    account.currentOrganization.dismissedObjects.some(
      (item) => item.id === objectId,
    ) || false
  )
}
