declare global {
  interface Window {
    UPLOADCARE_PUBLIC_KEY: string
    UPLOADCARE_LOCALE: string
    UPLOADCARE_LOCALE_TRANSLATIONS: object
  }
}

window.UPLOADCARE_LOCALE = 'en'
window.UPLOADCARE_LOCALE_TRANSLATIONS = {
  dialog: {
    tabs: {
      preview: {
        image: {
          title: 'Edit your image',
        },
      },
    },
  },
}

export {}
