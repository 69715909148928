import type { DefaultTheme } from 'styled-components'
import * as Animations from './animations'

export interface AppTheme {
  animation: {
    keyframes: typeof Animations
    duration: string
  }
}

export interface ComposerTheme {
  constants: {
    linkAttachmentThumbnailWidth: string
    linkAttachmentThumbnailHeight: string
  }
}

export const theme: DefaultTheme = {
  animation: { keyframes: { ...Animations }, duration: '200ms' },
  composer: {
    constants: {
      linkAttachmentThumbnailWidth: '205px',
      linkAttachmentThumbnailHeight: '108px',
    },
  },
}
