import React from 'react'
import PropTypes from 'prop-types'

import RouteMonitor from '../RouteMonitor'

// @ts-expect-error
const Loader = ({ location }) => <RouteMonitor pathname={location.pathname} />

Loader.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}
Loader.defaultProps = {
  location: {},
}

export default Loader
