import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { HelpScoutBeacon } from '../../helpScoutBeacon'

const RouteMonitor = ({ pathname }: { pathname: string }): null => {
  useEffect(() => {
    // Let Beacon know when we've changed pages
    if (window && window.Beacon) {
      HelpScoutBeacon.updateHelpScoutBeacon(pathname)
    }
  }, [pathname])
  return null
}

RouteMonitor.propTypes = {
  pathname: PropTypes.string,
}

RouteMonitor.defaultProps = {
  pathname: '',
}

export default RouteMonitor
