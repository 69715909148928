import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { getURL } from '~publish/legacy/utils/formatters'
import { createNew } from '~publish/legacy/routes'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import type { RouterActions } from 'redux-first-history'
import { useSplitEnabled } from '@buffer-mono/features'

type Action = () => void

export const useWelcomeCheckListActions = () => {
  const dispatch = useDispatch()

  const { isEnabled: isChannelStorefrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )

  const onCreateIdeaClick = (): RouterActions => dispatch(createNew.goTo())

  const handleConnectChannel = (): void => {
    if (isChannelStorefrontEnabled) {
      const { actions } = window.appshell
      actions.openChannelStorefront()
    } else {
      window.location.assign(getURL.getChannelConnectURL())
    }
  }

  const onPublishFirstPostClick = ({
    selectedProfileId,
    selectedProfileIds,
  }: {
    selectedProfileId: string | undefined
    selectedProfileIds?: string[]
  }): void => {
    dispatch(
      composerPopoverActions.handleCreatePostClick({
        selectedProfileId,
        selectedProfileIds,
        cta: SEGMENT_NAMES.CALENDAR_CREATE_A_POST,
      }),
    )
  }
  // NOTE: Currently hardcoded for showing checklist 1 only. We'll make this more dynamic as we add checklists
  const noOp = useCallback(() => undefined, [])
  const WELCOME_CHECKLIST_LINKS: Record<number, Action> = {
    1: () => noOp(),
    2: () => handleConnectChannel(),
    3: () => onCreateIdeaClick(),
    4: () =>
      onPublishFirstPostClick({
        selectedProfileId: undefined,
        selectedProfileIds: [],
      }),
  }

  // Create a method to call actions
  const callWelcomeChecklistAction = (action: number): void => {
    WELCOME_CHECKLIST_LINKS[action]()
  }

  return { callWelcomeChecklistAction }
}
