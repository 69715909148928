import styled from 'styled-components'
import { gray, grayLight, blue, grayLighter } from '@bufferapp/ui/style/colors'

export const Body = styled.div``

export const ChecklistItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 8px 0 8px;

  border-bottom: 1px solid ${grayLight};

  :hover {
    background-color: ${grayLighter};

    cursor: ${(props): string =>
      // @ts-expect-error TS(2339) FIXME: Property 'initial' does not exist on type 'ThemedS... Remove this comment to see the full error message
      props.initial ? 'default' : 'pointer'};
  }

  > svg {
    fill: ${gray};
  }
`

export const ChecklistItemLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  text-decoration: ${(props): string =>
    // @ts-expect-error TS(2339) FIXME: Property 'isCompleted' does not exist on type 'The... Remove this comment to see the full error message
    props.isCompleted ? 'line-through' : 'none'};
`

export const CheckOff = styled.div`
  border: 1px solid ${gray};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  background-color: white;
`

export const CheckOn = styled.div`
  background-color: ${blue};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    fill: white;
  }
`
