import styled from 'styled-components'
import { grayDark } from '@bufferapp/ui/style/colors'

export const Footer = styled.div`
  padding-left: 10px;

  > p {
    font-size: 12px;
    color: ${grayDark};
  }

  #checklist-help-link {
    color: ${grayDark};
  }
`
