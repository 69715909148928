import React from 'react'
import ReactDOM from 'react-dom/client'
import { PublishRoot } from './components/PublishRoot'

const rootEl = document.getElementById('root')
if (rootEl) {
  ReactDOM.createRoot(rootEl).render(<PublishRoot />)
} else {
  console.error('Could not find root element to mount publish-frontend.')
}
