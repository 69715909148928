import * as Toast from '@radix-ui/react-toast'
import styled, { keyframes } from 'styled-components'
import { grayDarker, white } from '@bufferapp/ui/style/colors'
import { borderRadius } from '@bufferapp/ui/style/borders'

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
})

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${25}px))` },
  to: { transform: 'translateX(0)' },
})

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + ${25}px))` },
})

export const StyledViewport = styled(Toast.Viewport)`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 327px;
  max-width: 100vw;
  margin: 0;
  z-index: 9999;
  list-style: none;
  outline: none;
`

export const StyledToast = styled(Toast.Root)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${white};
  background-color: ${grayDarker};
  border-radius: ${borderRadius};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
  padding: 16px;
  gap: 8px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;

  @media (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
      animation: ${hide} 100ms ease-in;
    }
    &[data-swipe='move'] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }
    &[data-swipe='cancel'] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }
    &[data-swipe='end'] {
      animation: ${swipeOut} 100ms ease-out;
    }
  }
`

export const StyledToastClose = styled(Toast.Close)`
  cursor: pointer;
`
