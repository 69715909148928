import type { Checklist } from '~publish/legacy/welcome-checklist/types'

const getFirstIncompleteChecklist = (checklistCompletions: boolean[]) => {
  return checklistCompletions.findIndex((completion) => completion === false)
}

export const selectChecklist = (checklists: Checklist[]): Checklist | null => {
  if (!checklists || checklists.length === 0) {
    return null
  }

  // Create ordered array of booleans representing isChecklistComplete
  const checklistCompletions = checklists.map(
    (checklist) => !checklist.items.some((item) => item.isCompleted === false),
  )

  // Get index of first incomplete checklist
  const firstIncompleteChecklistIndex =
    getFirstIncompleteChecklist(checklistCompletions)

  // If no incomplete checklists, return last checklist
  if (firstIncompleteChecklistIndex === -1) {
    return checklists[checklists.length - 1]
  }

  return checklists[firstIncompleteChecklistIndex]
}
