import { connect } from 'react-redux'

import TemporaryDashboardBanner from './components/TemporaryDashboardBanner'
import {
  hasAtLeastOneProfileWithRemindersAndNoPushNotifications,
  getUsernamesOfProfilesWithRemindersAndNoPushNotifications,
} from './utils/getRemindersStatus'

export default connect((state) => {
  // @ts-expect-error TS(2339) FIXME: Property 'temporaryBanner' does not exist on type ... Remove this comment to see the full error message
  const remindersStatus = state.temporaryBanner.remindersStatusByProfile
  const displayRemindersBanner =
    (remindersStatus &&
      hasAtLeastOneProfileWithRemindersAndNoPushNotifications(
        remindersStatus,
      )) ||
    false
  let usernamesList = ''
  if (displayRemindersBanner) {
    usernamesList =
      getUsernamesOfProfilesWithRemindersAndNoPushNotifications(remindersStatus)
  }
  return {
    // @ts-expect-error TS(2339) FIXME: Property 'temporaryBanner' does not exist on type ... Remove this comment to see the full error message
    enabledApplicationModes: state.temporaryBanner.enabledApplicationModes,
    displayRemindersBanner,
    usernamesRemindersList: usernamesList,
  }
})(TemporaryDashboardBanner)
