import styled from 'styled-components'

import {
  grayDark,
  grayLight,
  grayDarker,
  blue,
} from '@bufferapp/ui/style/colors'

export const Header = styled.div`
  position: relative;
  background: linear-gradient(92.83deg, #e5eaff 0.48%, #ebeeff 98.57%);
  border-bottom: 1px solid ${grayLight};
  border-radius: 3px 3px 0 0;
`

export const ChecklistTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 40px;
  padding: 0 8px;

  > p {
    font-weight: 500;
    font-size: 15px;
    color: ${grayDarker};
  }
`

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px 0 8px;

  > p {
    white-space: nowrap;
    margin: 0 8px 12px 0;
    font-size: 12px;
    color: ${grayDark};
  }

  .checklistProgress {
    margin: 0px;
    margin-bottom: 12px;
    background: white;

    > div {
      background-color: ${blue};
    }
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  width: 64px;
  height: 54px;
  right: -6px;
  top: -26px;
`

export const DismissButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;

  svg {
    fill: ${grayDark};
    width: 12px;
    height: 12px;
    &:hover {
      fill: ${grayDarker};
      cursor: pointer;
    }
  }
`
