import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  .uploadcare--menu { display: none; }
  .uploadcare--powered-by { display: none; }
  .uploadcare--button_primary, .uploadcare--button_primary:hover { background: #2C4BFF; border-color: #2C4BFF; }
  .uploadcare-tab-effects--cancel { display: none; }
  .uploadcare--dialog__close { border: 1px solid #2C4BFF; }
  .uploadcare--button_muted:hover, .uploadcare--button_muted:focus, .uploadcare--button:active { color: #2C4BFF; }
  .uploadcare--button:focus { outline: 2px solid #2C4BFF; }
  .uploadcare-tab-effects--effect-button:hover { color: #2C4BFF; fill: #2C4BFF; }
  .uploadcare--crop-sizes__item[data-caption="540:283"]::after { content: '1.91:1'; }
`

export default GlobalStyles
