/**
 * Extracts UTM parameters from a given URLSearchParams object.
 *
 * This function looks for the following UTM parameters:
 * - utm_source
 * - utm_medium
 * - utm_campaign
 * - utm_term
 * - utm_content
 *
 * If any of these parameters are present in the URLSearchParams object,
 * they will be added to the returned object.
 *
 * @param {URLSearchParams} urlParams - The URLSearchParams object containing the query parameters.
 * @returns {Object} An object containing the extracted UTM parameters.
 */

interface UTMParams {
  [key: string]: string | undefined
}

export function getUTMParams(urlParams?: URLSearchParams): UTMParams {
  const utmParams: UTMParams = {}

  // Define the UTM parameters we are interested in
  const utmKeys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ]

  if (urlParams) {
    utmKeys.forEach((key) => {
      const value = urlParams?.get(key)
      if (value) {
        utmParams[key] = value
      }
    })
  }

  return utmParams
}
