import React from 'react'
import * as Toast from '@radix-ui/react-toast'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import { gray } from '@bufferapp/ui/style/colors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { StyledToast, StyledViewport, StyledToastClose } from './styles'
import {
  removeNotification,
  selectAllNotifications,
} from '../notificationsSlice'

export const Notifications = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const notifications = useAppSelector(selectAllNotifications)

  return (
    <Toast.Provider swipeDirection="right">
      {notifications.map((notification) => (
        <StyledToast
          style={{ pointerEvents: 'auto' }}
          key={notification.id}
          duration={notification.duration}
        >
          <Toast.Description>
            <span>{notification.message}</span>
          </Toast.Description>
          <StyledToastClose asChild>
            <CrossIcon
              color={gray}
              onClick={() => dispatch(removeNotification(notification.id))}
            />
          </StyledToastClose>
        </StyledToast>
      ))}
      <StyledViewport />
    </Toast.Provider>
  )
}
