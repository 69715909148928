import React from 'react'
import { useAccount } from '~publish/legacy/accountContext'
import WelcomeChecklist from '~publish/legacy/welcome-checklist/components/WelcomeChecklist/WelcomeChecklist'
import { Container } from './style'
import { HelpScoutBeacon } from '~publish/legacy/thirdParty/helpScoutBeacon'

const WELCOME_CHECKLIST_START_DATE = new Date('2023-01-23T00:00:00.000Z')

// This component serves as an additional wrapper to the WelcomeChecklist logic.
// Here we determine whether a user in the split group is eligible to see the checklist.
const WelcomeChecklistContainer = (): JSX.Element => {
  const { account } = useAccount()

  const isOneBufferOrganization =
    account?.currentOrganization?.isOneBufferOrganization
  const orgCreatedAt =
    new Date(account?.currentOrganization?.createdAt || '2000-01-01') || null
  const isLaunchEligible = orgCreatedAt > WELCOME_CHECKLIST_START_DATE || false

  const isHelpScoutBeaconVisible = HelpScoutBeacon.isBeaconVisible()

  return (
    <Container isHelpScoutBeaconVisible={isHelpScoutBeaconVisible}>
      {isOneBufferOrganization && isLaunchEligible && <WelcomeChecklist />}
    </Container>
  )
}

export default WelcomeChecklistContainer
