import type {
  Checklist,
  ChecklistProgress,
} from '~publish/legacy/welcome-checklist/types'

export const getChecklistProgress = (
  checklist: Checklist,
): ChecklistProgress => {
  if (!checklist || !checklist.items?.length || checklist.items?.length < 1) {
    return {
      itemsCompleted: 0,
      itemsTotal: 0,
      progressPercent: 0,
    }
  }

  const itemsCompleted = checklist.items.filter((item) => item.isCompleted)
  const itemsTotal = checklist.items.length
  return {
    itemsCompleted: itemsCompleted.length,
    itemsTotal,
    progressPercent: Math.round((itemsCompleted.length / itemsTotal) * 100),
  }
}
