import React from 'react'
import { Banner } from '@bufferapp/ui'
import { preferencesGeneral } from '~publish/legacy/routes'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

export const OptedOutBanner = (): JSX.Element => {
  const dispatch = useDispatch()
  const [isNewPublishEnabled] = usePublishRevamp()
  const isPreferencesPage = !!useRouteMatch(preferencesGeneral.route)

  const excluding = true
  if (excluding) {
    return <></>
  }

  if (isNewPublishEnabled || isPreferencesPage) {
    return <></>
  }

  return (
    <Banner
      // @ts-ignore
      text="You've opted to temporarily disable the new Publish experience. It will be permanently re-enabled in 24 days."
      actionButton={{
        label: 'Re Enable Now',
        action: (): void => {
          dispatch(preferencesGeneral.goTo())
        },
      }}
      themeColor="orange"
    />
  )
}
