// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
export const filterProfilesWithRemindersAndNoPushNotifications = (profiles) => {
  if (profiles) {
    const listProfiles = profiles.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
      (profile) =>
        !profile.has_push_notifications && profile.has_reminders_in_queue,
    )
    return listProfiles
  }
  return []
}

export const hasAtLeastOneProfileWithRemindersAndNoPushNotifications = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
  profiles,
) => {
  const result = filterProfilesWithRemindersAndNoPushNotifications(profiles)
  return result && result.length > 0
}

export const getUsernamesOfProfilesWithRemindersAndNoPushNotifications = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
  profiles,
) => {
  const usernamesList = filterProfilesWithRemindersAndNoPushNotifications(
    profiles,
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
  ).map((profile) => `@${profile.formatted_username}`)

  return usernamesList.join(', ')
}
