import React from 'react'
import { Popover, Button, Heading, Paragraph, Flex } from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'
import { useAccount } from '~publish/legacy/accountContext'
import { isFreeUser } from '~publish/helpers/user'
import type { DismissableBanner } from '~publish/hooks/useDismissableBanner'

import styles from './WelcomeChecklist.module.css'

export const WelcomeCheckListPopover = ({
  children,
  banner,
}: {
  children: JSX.Element
  banner: DismissableBanner
}): JSX.Element => {
  const { account } = useAccount()
  const isFree = isFreeUser(account)

  const { isEnabled: isSplitEnabled } = useSplitEnabled(
    'geid-skip-onboarding-free-users',
    {
      email: account?.email,
    },
  )

  if (!banner.isActive || !isSplitEnabled || !isFree) {
    return children
  }

  return (
    <Popover defaultOpen>
      <Popover.Trigger>{children}</Popover.Trigger>

      <Popover.Content
        side={'left'}
        align={'start'}
        arrow
        className={styles.popover}
      >
        <Flex direction="column" align="start" gap="sm">
          <Heading size="small">Hey! Welcome to Buffer 👋</Heading>
          <Paragraph>
            Here's a checklist we put together to help you get started. Follow
            these steps to start scheduling content with ease.
          </Paragraph>
          <Flex direction="column" align="end">
            <Button onClick={banner.dismiss}>Got it</Button>
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover>
  )
}
