import { connect } from 'react-redux'
import { organization } from '~publish/legacy/routes'
import MissingAccessPage from './components/MissingAccessPage'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'i18n' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    translations: state.i18n.translations['missing-access-page'],
  }),
  (dispatch) => ({
    switchOrganization(orgId: string): void {
      dispatch(organization.goTo({ orgId }))
    },
  }),
)(MissingAccessPage)
