import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useAccount } from '~publish/legacy/accountContext'

import type { Checklist } from '../../types'

import { actions as welcomeChecklistRefetchOperations } from '../../reducer'
import { useChecklists } from '../hooks/useChecklists'
import { selectChecklist } from '../utils/selectChecklist'
import { getChecklistProgress } from '../utils/getChecklistProgress'

import ChecklistHeader from '../ChecklistHeader'
import ChecklistBody from '../ChecklistBody'
import ChecklistFooter from '../ChecklistFooter'
import { hasDismissedObject } from '~publish/legacy/utils/dismissedObjects/hasDismissedObject'
import { useWelcomeCheckListActions } from '../hooks/useWelcomeChecklistActions'
import { WelcomeCheckListPopover } from './WelcomeCheckListPopover'

import styles from './WelcomeChecklist.module.css'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

const COMPLETED_PROGRESS = 100
const CHECKLIST_MODAL_DIMISSED_LOCAL_STORAGE_KEY =
  'isWelcomeChecklistModalDismissed'
const CHECKLIST_COMPLETE_MODAL_DIMISSED_LOCAL_STORAGE_KEY =
  'isChecklistCompleteModalDismissed'
const WELCOME_CHECKLIST_COMPLETE = 'welcome_checklist_complete'
const WELCOME_CHECKLIST_SUPPRESS_MODAL = 'welcome_checklist_suppress_modal'

export const WelcomeChecklist = (): JSX.Element | null => {
  const { account } = useAccount()
  const dispatch = useDispatch()
  const { checklists, loading, error, refetch } = useChecklists()
  const { callWelcomeChecklistAction } = useWelcomeCheckListActions()
  const [dismissedWelcomeChecklist, setDismissedWelcomeChecklist] =
    React.useState(false)
  const popoverBanner = useDismissableBanner('welcome-checklist-popover')

  // Check if we should refetch the checklist status based on app state
  // Check if we should refetch the checklist status based on app state
  const shouldRefetch = useSelector(
    // @ts-expect-error TS(2339) FIXME: Property 'welcomeChecklist' does not exist on type... Remove this comment to see the full error message
    (state) => state.welcomeChecklist.shouldRefetch,
  )

  React.useEffect(() => {
    const dismissed =
      sessionStorage.getItem(CHECKLIST_MODAL_DIMISSED_LOCAL_STORAGE_KEY) ===
      'dismissed'
    setDismissedWelcomeChecklist(dismissed)
  }, [])

  useEffect(() => {
    if (shouldRefetch) {
      refetch()
      dispatch(welcomeChecklistRefetchOperations.clearShouldRefetch())
    }
  }, [dispatch, refetch, shouldRefetch, account])

  let checklist: Checklist | null = null
  let checklistProgress = {
    itemsCompleted: 0,
    itemsTotal: 0,
    progressPercent: 0,
  }
  if (checklists) {
    checklist = selectChecklist(checklists)
    checklistProgress = checklist
      ? getChecklistProgress(checklist)
      : checklistProgress
  }

  const handleChecklistItemClick = (
    id: number,
    checklistItemDescription: string,
    isCompleted: boolean,
  ): void => {
    // Don't track clicks on the first item
    if (id === 1) {
      return
    }
    const eventData = {
      organizationId: account?.currentOrganization?.id || '',
      checklistId: checklist?.id || 0,
      checklistItem: checklistItemDescription || '',
      isCompleted: isCompleted || false,
      qtyItemsCompleted: checklistProgress.itemsCompleted,
    }

    BufferTracker.checklistItemClicked(eventData)
    popoverBanner.dismiss()
    callWelcomeChecklistAction(id)
  }

  const onDismissWelcomeChecklist = (): void => {
    sessionStorage.setItem(
      CHECKLIST_MODAL_DIMISSED_LOCAL_STORAGE_KEY,
      'dismissed',
    )
    popoverBanner.dismiss()
    setDismissedWelcomeChecklist(true)
  }

  const dismissedChecklist = hasDismissedObject({
    account,
    objectId: WELCOME_CHECKLIST_COMPLETE,
  })
  const isChecklistCompleteModalDismissed =
    dismissedChecklist ||
    !!localStorage.getItem(CHECKLIST_COMPLETE_MODAL_DIMISSED_LOCAL_STORAGE_KEY)

  // NOTE: After geid-first-post-celebration experiment is complete - we can remove the useEffect below, but keep the modalSuppressed value here
  // to ensure that the modal stays suppressed for users who have already auto-dismissed it via this experiment
  const modalSuppressed = hasDismissedObject({
    account,
    objectId: WELCOME_CHECKLIST_SUPPRESS_MODAL,
  })

  const isChecklistComplete =
    checklistProgress.progressPercent === COMPLETED_PROGRESS

  useEffect(() => {
    const { MODALS, actions } = window?.appshell || {}
    if (
      actions &&
      isChecklistComplete &&
      !isChecklistCompleteModalDismissed &&
      !modalSuppressed &&
      !dismissedWelcomeChecklist
    ) {
      // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
      actions.openModal(MODALS.checklistComplete)
    }
  }, [isChecklistComplete, isChecklistCompleteModalDismissed])

  // Hide the checklist if we've seen the modal and the checklist is complete
  const shouldHideChecklist =
    isChecklistCompleteModalDismissed ||
    isChecklistComplete ||
    dismissedWelcomeChecklist

  useEffect(() => {
    if (!shouldHideChecklist && account?.currentOrganization) {
      BufferTracker.welcomeChecklistViewed({
        organizationId: account?.currentOrganization?.id,
        qtyItemsCompleted: checklistProgress.itemsCompleted,
      })
    }
  }, [account, checklistProgress.itemsCompleted, shouldHideChecklist])

  if (shouldHideChecklist || !checklist || !checklists || loading || error) {
    return null
  }

  return (
    <WelcomeCheckListPopover banner={popoverBanner}>
      <Flex
        direction="column"
        align="stretch"
        justify="start"
        className={styles.welcomeChecklistWrapper}
        data-testid="welcome_checklist"
      >
        <ChecklistHeader
          checklist={checklist}
          checklistProgress={checklistProgress}
          onDismissWelcomeChecklist={onDismissWelcomeChecklist}
        />
        <ChecklistBody
          checklist={checklist}
          handleChecklistItemClick={handleChecklistItemClick}
        />
        <ChecklistFooter />
      </Flex>
    </WelcomeCheckListPopover>
  )
}

export default WelcomeChecklist
