import React from 'react'
import { Footer } from './style'
import { Text } from '@bufferapp/ui'

const ChecklistFooter = (): JSX.Element => {
  return (
    <Footer>
      <Text type="p">
        Need some help?{' '}
        <a
          id="checklist-help-link"
          href="https://support.buffer.com/article/600-getting-started-with-buffers-publishing-features"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read our guide
        </a>
      </Text>
    </Footer>
  )
}

export default ChecklistFooter
