import styled from 'styled-components'

export interface IContainerProps {
  isHelpScoutBeaconVisible: boolean
}

export const Container = styled.div<IContainerProps>`
  position: absolute;
  right: ${(props): string =>
    props.isHelpScoutBeaconVisible ? '120px' : '40px'};
  bottom: 24px;
`
