import React from 'react'
import type { Checklist } from '~publish/legacy/welcome-checklist/types'
import {
  Body,
  ChecklistItem,
  ChecklistItemLeft,
  CheckOn,
  CheckOff,
} from './style'
import { Text } from '@bufferapp/ui'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import CaretRightIcon from '@bufferapp/ui/Icon/Icons/CaretRight'

const ChecklistBody = ({
  checklist,
  handleChecklistItemClick,
}: {
  checklist: Checklist
  handleChecklistItemClick: (
    id: number,
    description: string,
    isCompleted: boolean,
  ) => void
}): JSX.Element => {
  return (
    <Body>
      {checklist.items.map((item) => {
        return (
          <ChecklistItem
            key={item.description}
            onClick={(): void => {
              handleChecklistItemClick(
                item.id,
                item.description,
                item.isCompleted,
              )
            }}
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            initial={item.id === 1}
          >
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <ChecklistItemLeft isCompleted={item.isCompleted}>
              {item.isCompleted ? (
                <CheckOn>
                  <CheckmarkIcon size="small" />
                </CheckOn>
              ) : (
                <CheckOff />
              )}
              <Text type="p">{item.description}</Text>
            </ChecklistItemLeft>
            {item.id !== 1 && <CaretRightIcon size="smedium" />}
          </ChecklistItem>
        )
      })}
    </Body>
  )
}

export default ChecklistBody
