import { connect } from 'react-redux'
import { actions as modalReducers } from '~publish/legacy/modals/reducer'

import ThirdParty from './components/Loader'

export default connect((state) => {
  // @ts-expect-error TS(2339) FIXME: Property 'modals' does not exist on type 'DefaultR... Remove this comment to see the full error message
  const { modals } = state

  const modalsShowing = modalReducers.isShowingModals({ modals })

  return {
    modalsShowing,
    // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    userId: state.user?.id,
  }
})(ThirdParty)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
